@import "scssVariables";

.styled-heat-map-chat-wrapper {
  div {
    box-sizing: border-box;
    font-family: $font-semi-bold;
    font-size: 12px;
    color: var(--light-blue);
    white-space: nowrap;
  }
  .cell-content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    &:hover {
      .cell-content {
        opacity: 1;
      }
    }
    .cell-content {
      position: absolute;
      opacity: 0;
      background: var(--white);
      color: var(--blue);
      border-radius: 2px;
      min-width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      padding: 5px;
      box-sizing: border-box;
      border: 2px solid var(--pink);
      font-size: 14px;
      font-family: $font-bold;
      transform: translate(-50%, -50%);
      transition: .3s all;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}
.logo {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  @media (max-width: 1300px) {
    padding: 20px;
  }

  img {
    width: 34px;
    height: 39px;
  }

  .search-deploy {
    font-size: 18px;
    color: #213991;
    text-decoration: none;
    margin: 0 0 0 20px;
  }
}

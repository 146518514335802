body {
  font-family: "Neutrif Pro";
  margin: 0;
  overflow: hidden;
}

.pac-container {
  background-color: #FFF;
  z-index: 2001;
  position: fixed;
  display: inline-block;
  float: left;
}

@font-face {
  font-family: "SegoeUI";
  src: url(./assets/fonts/SegoeUI/SegoeUI.ttf);
}

@font-face {
  font-family: "SegoeUI-light";
  src: url(./assets/fonts/SegoeUI/SegoeUI-Light.ttf);
}

@font-face {
  font-family: "SegoeUI-semi-bold";
  src: url(./assets/fonts/SegoeUI/SegoeUI-SemiBold.ttf);
}

@font-face {
  font-family: "SegoeUI-bold";
  src: url(./assets/fonts/SegoeUI/SegoeUI-Bold.ttf);
}

@font-face {
  font-family: "Neutrif Pro";
  src: url(./assets/fonts/NeutrifPro/NeutrifProRegular[TheFontsMaster.com].otf);
}
@font-face {
  font-family: "Neutrif Pro, bold";
  src: url(./assets/fonts/NeutrifPro/NeutrifProBold[TheFontsMaster.com].otf);
}
@font-face {
  font-family: "Neutrif Pro, bold italic";
  src: url(./assets/fonts/NeutrifPro/NeutrifProBoldItalic[TheFontsMaster.com].otf);
}
@font-face {
  font-family: "Neutrif Pro, italic";
  src: url(./assets/fonts/NeutrifPro/NeutrifProItalic[TheFontsMaster.com].otf);
}
@font-face {
  font-family: "Neutrif Pro, light";
  src: url(./assets/fonts/NeutrifPro/NeutrifProLight[TheFontsMaster.com].otf);
}
@font-face {
  font-family: "Neutrif Pro, light italic";
  src: url(./assets/fonts/NeutrifPro/NeutrifProLightItalic[TheFontsMaster.com].otf);
}
@font-face {
  font-family: "Neutrif Pro, medium";
  src: url(./assets/fonts/NeutrifPro/NeutrifProMedium[TheFontsMaster.com].otf);
}
@font-face {
  font-family: "Neutrif Pro, mudium italic";
  src: url(./assets/fonts/NeutrifPro/NeutrifProMediumItalic[TheFontsMaster.com].otf);
}
@font-face {
  font-family: "Neutrif Pro, semi bold";
  src: url(./assets/fonts/NeutrifPro/NeutrifProSemiBold[TheFontsMaster.com].otf);
}
@font-face {
  font-family: "Neutrif Pro, semi bold italic";
  src: url(./assets/fonts/NeutrifPro/NeutrifProSemiBoldItalic[TheFontsMaster.com].otf);
}

/*----------------STYLE FOR AUDIENCES > DBC------------------------*/

.MuiTreeItem-root:focus > .MuiTreeItem-content{
  background: transparent !important;
}

/*--------------LOADER ANIMATION--------------------*/
@keyframes ring{
  100%{
   transform: rotate(360deg);
  }
 }

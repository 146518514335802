@import "scssVariables";

.terms-cond-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: var(--card-bg);
  padding: 24px;
  box-sizing: border-box;
  @include scroll-styles();
  @media (max-width: $breakpoint-mobile) {
    padding: 24px 16px;
  }
  .terms-content {
    padding: 30px 0;
    max-width: 1500px;
    margin: 0 auto;
    .terms-title {
      color: var(--blue);
      font-family: $font-bold;
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 20px;
      text-align: center;
      @media (max-width: $breakpoint-mobile) {
        font-size: 26px;
        line-height: 32px;
      }
    }
    .terms-subtitle {
      color: var(--blue);
      font-family: $font-bold;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      max-width: 1000px;
      margin: 0 auto 50px;
      @media (max-width: $breakpoint-mobile) {
        font-size: 18px;
        line-height: 20px;
      }
    }
    .content-text {
      font-size: 16px;
      font-family: $font-semi-bold;
      color: var(--light-blue);
      margin-bottom: 20px;
      span {
        color: var(--blue);
        font-family: $font-bold;
      }
    }
  }
}

$breakpoint-tablet: 1060px;
$breakpoint-mobile: 767px;

// FONTS

$font-thin: "Neutrif Pro, light";
$font-regular: "Neutrif Pro";
$font-semi-bold: "Neutrif Pro, medium";
$font-bold: "Neutrif Pro, bold";

// MIXINS

@mixin scroll-styles() {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    position: absolute;
    background: var(--gray);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb{
    background: var(--scroll-color);
    border-radius: 5px;
  }
}

@mixin text-overflow($width) {
  width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin popover-base($padding) {
  box-shadow: 0 6px 32px rgba(0, 0, 0, 0.17);
  border-radius: 6px;
  background: var(--white);
  border: 1px solid var(--dark-gray-border);
  padding: $padding;
}

@mixin dashboard-card-base() {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  background: var(--card-bg);
  padding: 16px;
  box-sizing: border-box;
  transition: .3s all;
}

@mixin statistic-popover-item($padding) {
  font-size: 14px;
  line-height: 20px;
  font-family: $font-semi-bold;
  color: var(--blue);
  text-transform: unset;
  padding: $padding;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  transition: .3s all;
  border-radius: 0;
  &:hover {
    background: transparent;
  }
  &.active {
    color: var(--bright-blue);
  }
}

@mixin icon-button($padding) {
  min-width: unset;
  padding: $padding;
  &:hover {
    background: transparent;
    path {
      stroke: var(--bright-blue);
    }
  }
  &:before {
    content: "";
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    border: 1px solid var(--bright-blue);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .3s all;
    opacity: 0;
  }
  &.active {
    &:before {
      opacity: 1;
    }
    path {
      stroke: var(--bright-blue);
    }
  }
  path {
    stroke: var(--light-gray3);
    transition: .3s all;
  }
  .MuiTouchRipple-root {
    display: none;
  }
}

@mixin button-base($padding) {
  border-radius: 6px;
  font-family: $font-semi-bold;
  font-size: 14px;
  text-transform: unset;
  min-width: 75px;
  padding: $padding;
  box-sizing: border-box;
  transition: .3s all;
  &.Mui-disabled {
    opacity: .5;
  }
}

@mixin red-btn($padding) {
  @include button-base($padding);
  color: var(--white);
  background: var(--red1);
  &:hover {
    background: var(--red1);
  }
  &.Mui-disabled {
    color: var(--white);
  }
}

@mixin gray-btn($padding) {
  @include button-base($padding);
  color: var(--blue);
  background: var(--main-bg);
  &:hover {
    background: var(--main-bg-hover);
  }
  &.Mui-disabled {
    color: var(--blue);
  }
}

@mixin blue-btn($padding) {
  @include button-base($padding);
  color: var(--blue-btn-color);
  background: var(--blue-btn-bg);
  &:hover {
    background: var(--dark-blue2);
  }
  &.Mui-disabled {
    color: var(--blue-btn-color);
  }
}

@mixin bright-blue-btn($padding) {
  @include button-base($padding);
  color: var(--blue-btn-color);
  background: var(--bright-blue);
  &:hover {
    background: var(--dark-blue);
  }
  &.Mui-disabled {
    color: var(--blue-btn-color);
  }
}

@mixin close-modal-btn() {
  min-width: unset;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 0;
  transition: .3s all;
  &:hover {
    background: var(--light-gray5);
  }
}

@mixin page-title() {
  font-size: 40px;
  line-height: 50px;
  color: var(--blue);
  font-family: $font-bold;
  @media (max-width: $breakpoint-mobile) {
    font-size: 26px;
    line-height: 32px;
  }
}

@mixin table-sort-arrows() {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0, -50%);
  @media (max-width: 1350px) {
    right: 5px;
  }
  &:before, &:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 1px;
    background: var(--light-gray3);
    top: 50%;
    transition: .3s all;
  }
  &:before {
    left: -1px;
    transform: rotate(-45deg);
  }
  &:after {
    right: -1px;
    transform: rotate(45deg);
  }
  &.active {
    &:before, &:after {
      background: var(--blue);
    }
  }
  &.to-top {
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
